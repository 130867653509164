import React from 'react';
import { Link } from 'gatsby';

import { sanityClient } from '@lib/sanity';
import { useAddItemsToCart } from '@lib/context';

//

import { SanityImage } from '@components/sanity/sanityImage';
import { ProductPrice } from '@components/product/productPrice';

import { PortableTextBlock } from '../sanity/portableTextBlock';

const ProductTeaser = ({ title, price, bottleShot, slug, productDetail }) => {
  return (
    <Link className="flex" to={`/products/${slug?.current}`}>
      <div className="w-16 flex-shrink-0 lg:w-24">
        <div className="aspect-w-4 aspect-h-3 relative">
          <SanityImage
            image={bottleShot}
            className="asbolute w-full h-full top-0 left-0 object-contain object-bottom"
          />
        </div>
      </div>
      <div>
        <h4 className="text-sm font-medium md:text-xl">{title}</h4>
        <h5 className="text-xs font-medium py-[0.2rem]">{productDetail}</h5>
        <div className="text-xs font-medium md:text-base">
          <ProductPrice price={price} />
        </div>
      </div>
    </Link>
  );
};

const RecipieCard = ({
  image,
  title,
  products,
  ingredients,
  instructions,
  bgColor,
  reverseText,
}) => {
  const [recipieProducts, setRecipieProducts] = React.useState(products);

  // Enrich our products with their variants
  React.useEffect(() => {
    const fetchVariants = async () => {
      const productVariants = await Promise.all(
        recipieProducts.map(async (product) => {
          return sanityClient
            .fetch(`*[_type == "productVariant" && productID == ${product.productID}]`)
            .then((variants) => {
              console.log(variants);
              return { ...product, variants };
            });
        })
      );
      setRecipieProducts(productVariants);
    };
    // call the function
    fetchVariants()
      // make sure to catch any error
      .catch(console.error);
  }, []);

  const addTocart = useAddItemsToCart();

  const addItems = () => {
    const items = recipieProducts.map((item) => {
      return {
        id: item.variants[0].variantID,
        qty: 1,
      };
    });

    addTocart(items);
  };

  const bundlePrice = products.reduce((total, item) => +item.price + total, 0);

  return (
    <div
      style={{ backgroundColor: bgColor?.hex }}
      className="grid grid-cols-1 p-6 rounded-xl my-space-s gap-y-4 md:p-16 lg:grid-cols-2 lg:gap-x-16 2xl:p-24"
    >
      {/* Image */}
      <div className="">
        <div className="aspect-w-8 aspect-h-10 relative lg:sticky lg:top-16">
          <SanityImage image={image} className="absolute top-0 left-0 w-full h-full object-cover" />
        </div>
      </div>
      {/* Recipie Area */}
      <div className="self-start grid gap-y-4  lg:gap-y-10 lg:max-w-4xl lg:mx-auto 2xl:self-center">
        <h2 className="blockH1">{title}</h2>
        {/* Product List */}
        {products && products.length > 0 && (
          <div>
            <h2 className="blockH5 mb-one lg:blockH4">Main Ingredients</h2>
            {products.map((item) => (
              <ProductTeaser {...item} />
            ))}
            <button className="btn mt-6" onClick={addItems}>
              Add {products.length} to Cart <span className="px-2">-</span>
              <ProductPrice price={bundlePrice} />
            </button>
          </div>
        )}
        {ingredients.length > 0 && (
          <div>
            <h2 className="blockH5 mb-one lg:blockH4">Other Ingredients</h2>
            <div className="grid gap-y-2">
              {ingredients.map((item) => (
                <div>{item}</div>
              ))}
            </div>
          </div>
        )}
        <div>
          <h2 className="blockH5 mb-one lg:blockH4">Instructions</h2>
          <PortableTextBlock text={instructions} />
        </div>
      </div>
    </div>
  );
};

export default RecipieCard;
